<template scoped>
  <div class="designtool-add-logic">
    <div>
      <el-card class="box-card">
        <el-button plain @click="addLogic">Add Logic</el-button>
      </el-card>
    </div>
    <DesignToolLogic-Arrow-Bottom> </DesignToolLogic-Arrow-Bottom>
  </div>
</template>

<script scoped>
export default {
  props: {
    addLogic: Function,
  },
  components: {
    "DesignToolLogic-Arrow-Bottom": () =>
      import(
        "./Arrow-Bottom.vue"
      ),
  },
};
</script>

<style scoped>
.designtool\-add\-logic {
  width: 300px;
  text-align: center;
}
</style>

